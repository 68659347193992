import React, { useCallback, useState } from 'react'
import Gallery from 'react-photo-gallery'
import Carousel, { Modal, ModalGateway } from 'react-images'
import { graphql, useStaticQuery } from 'gatsby'
import { Grid } from '@mui/material'
import { Home, Collections } from '@mui/icons-material'

import Header from '../components/common/Header'
import SEO from '../components/common/seo'
import { buildImageLink } from '../helpers'
import { routePaths } from '../constants/routes'
import { SubContainer } from '../components/common/Styled'

const query = graphql`
  query {
    home: strapiHome {
      referencing {
        metaTitle
      }
    }
    gallery: strapiGallery {
      images {
        image {
          strapi_id
          url
          alternativeText
          width
          height
        }
      }
      referencing {
        metaTitle
      }
    }
  }
`

const GalleryPage = () => {
  const [currentImage, setCurrentImage] = useState(0)
  const [viewerIsOpen, setViewerIsOpen] = useState(false)

  const { gallery, home } = useStaticQuery(query)

  const openLightbox = useCallback((event, { index }) => {
    setCurrentImage(index)
    setViewerIsOpen(true)
  }, [])

  const closeLightbox = () => {
    setViewerIsOpen(false)
    setCurrentImage(0)
  }

  const photos = gallery?.images
    .map(({ image }) => {
      return {
        src: buildImageLink(image.url),
        width: image.width,
        height: image.height
      }
    })
    .reverse()

  const title = gallery.referencing.metaTitle

  const items = [
    {
      icon: <Home />,
      label: home.referencing.metaTitle,
      route: routePaths.home
    },
    {
      icon: <Collections />,
      label: title
    }
  ]

  return (
    <SubContainer customWidth={2000}>
      <SEO title="Galerie" />
      <Header {...{ items, title }} />
      <Grid
        mt={2}
        mx="auto"
        sx={{
          width: '80%'
        }}
      >
        <Gallery photos={photos} onClick={openLightbox} />
        <ModalGateway>
          {viewerIsOpen ? (
            <Modal onClose={closeLightbox}>
              <Carousel
                currentIndex={currentImage}
                views={photos.map(x => ({
                  ...x,
                  srcset: x.srcSet,
                  caption: x.title
                }))}
              />
            </Modal>
          ) : null}
        </ModalGateway>
      </Grid>
    </SubContainer>
  )
}

export default GalleryPage
